import React from 'react';
import { Link } from 'react-router-dom';

export const HomePage: React.FC = () => {
    return (
        <div className="h-screen flex flex-col">
            <div
                className="h-70 bg-cover bg-center relative"
                style={{ minHeight: "70vh", backgroundPosition: "bottom", backgroundSize: "cover", backgroundImage: "linear-gradient(to bottom, rgba(0,38,62, 0.90),rgba(0,38,62, 0.80), rgba(0,38,62, 0.70)),url('https://www.rolexgiraglia.com/easyUp/eBanner/1_gir22cb_12011625.jpg')" }}
            >
                <div className="mx-auto text-center p-6 text-white mt-32">
                    <h1 className="text-6xl font-semibold mb-2">Giraglia Database</h1>
                </div>
                <div className="mx-auto text-center p-6 text-white">
                    <h2 className="text-sm font-light mb-2 leading-4">Welcome to the official Giraglia database</h2>
                    <h2 className="text-sm font-light mb-2 leading-4">In this database you can find all the data related to the regattas from 1950 until today.</h2>
                </div>
                <div className="absolute bottom-0 left-0 right-0 text-center align-baseline mx-auto text-center p-6 text-white">
                    <p className="text-white font-bold">Sign up and start now!</p>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-auto">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                </div>
            </div>
            <div className="mx-auto mt-10">
                <Link to="/register"
                    className="bg-blue-500 rounded-lg bg-blue block w-[200px] hover:bg-blue-900 text-white font-bold py-2 px-4 rounded mx-auto text-center"  >
                    Sign up
                </Link>
                <p className="text-center mt-4 text-sm">
                    You already have an account? <Link to="/login" className="blue underline hover:text-blue-800">Log in.</Link>
                </p>
            </div>
        </div >
    );
};
