export default function Input({ children, ...props }: any) {
    return <div className="flex flex-col rounded-lg w-full mb-4">
        <label className="text-md font-bold blue text-left">{children}</label>
        <input
            {...props}
            className={"w-full h-10 mt-2 border-2 border-blue rounded-lg px-4 py-5 text-sm " + props.className}
            type={props?.type || "text"}
            onChange={props.onChange}
            placeholder={props?.placeholder}
        />
    </div>
}