import { useState } from "react";
import Navbar from "../components/navBar";
import { requestResetPassword } from "../libs/http";
import toast from 'react-hot-toast';
import FormStructure from "../components/form";
import Button from "../components/button";
import Input from "../components/Input";
import NavBar from "../components/navBar";

const ResetPasswordPage: React.FC = () => {
    const [email, setEmail] = useState("");

    const handleLogin = () => {
        toast.promise(
            requestResetPassword(
                email,
            ),
            {
                loading: 'Sending email...',
                success: () => {
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 1500);
                    return <b>Email sent!</b>;
                },
                error: <b>Could not send email</b>,
            }
        );
    }
    return <div className="h-screen"
        style={{ minHeight: "70vh", backgroundPosition: "bottom", backgroundSize: "cover", backgroundImage: "url('/giraglia.jpg')" }}
    >
       <NavBar
            breadCrumb={[]}
            title="Giraglia Database"
        />
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
            }}
        >
            <FormStructure>
                <Input type="text" placeholder="Email"
                onChange={(e: any) => setEmail(e.target.value)}
                >
                    Enter your email
                </Input>
                <Button
                    type="submit"
                >
                    Reset Password
                </Button>
            </FormStructure>
        </form>
    </div>
}
export default ResetPasswordPage;
