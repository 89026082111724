import { useEffect, useState } from "react";
import NavBar from "../components/navBar";
import { getRaces } from "../libs/http";
import { Link } from "react-router-dom";
import Loader from "../components/loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon, ChevronLeftIcon, ChevronUpIcon } from '@heroicons/react/20/solid'

const YearComponent = ({ year, image }: any) => {
    return (
        <Link to={`/years/${year}`}>
            <div className="flex gap-0 cursor-pointer mb-8 mt-4 rounded-xl relative h-[100px] md:h-[140px] w-[360px] md:w-[700px] mx-auto" style={{ backgroundImage: "url('/years.png')", backgroundSize: "cover", backgroundPosition: "left" }}>
                <div className="overflow-hidden rounded-lg w-full h-[100px] md:h-[140px] bg-[url('../public/RectangleMobile.svg')]  md:bg-[url('../public/Rectangle.svg')] bg-no-repeat bg-right bg-contain">
                    < div className="absolute right-0 overflow-hidden mx-auto" style={{ width: "100%", height: "100%", }}>
                        <div className="absolute right-6 bottom-0 top-0 flex h-10" style={{ margin: "auto 0" }}>
                            <span className="text-white text-xl md:text-3xl font-bold mr-2 md:mr-4 h-30 my-auto">
                                {year}
                            </span>
                            <div className="h-10 w-10 rounded-full align-middle mr-4" style={{ backgroundColor: "#00263E", margin: "auto 0" }}>
                                <img src={"/Arrow.svg"} className="w-4 h-4 mx-auto m-3" alt=""  ></img>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </Link>
    );
}

interface Year {
    collectionId: string,
    collectionName: string,
    created: string,
    expand: any,
    id: string,
    title: string,
    updated: string,
    year: number,
    image: string
}

export default function Years() {
    const [years, setYears] = useState<Year[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchYear, setSearchYear] = useState<string | undefined>();
    const [filteredYears, setFilteredYears] = useState<Year[]>([]);
    const fetchRaces = () => {
        getRaces(1, 100).then((res) => {
            setYears(res.items as unknown as Year[]);
            setLoading(false);
        }
        ).catch((err) => {
            console.log(err);
        }
        );
    }

    useEffect(() => {
        fetchRaces();
    }, []);

    useEffect(() => {
        if (searchYear) {
            setFilteredYears(years.filter((year) => {
                return year.year.toString().includes(searchYear);
            }));
        } else {
            setFilteredYears(years);
        }
    }, [searchYear, years]);

    return <div >
        <NavBar
            breadCrumb={[{
                name: "Select Search",
                link: "/"
            }, {
                name: "Search by race",
                link: "/years"
            }]}
            title="Search by race"
            requireAuth={true}
        />
        <FadeIn visible={!loading}>
            <div className="container mx-auto">
                <h1 className="text-5xl my-20  text-center font-bold hidden md:block">
                    Select the race you want to find.
                </h1>
                {
                    loading ? <div className="w-[300px] md:w-[700px] mx-auto justify-center text-center align-center">
                        {
                            Array.from(Array(5).keys()).map((i) => <Loader lineNumbers={4} className="mb-12 block pb-2" />)
                        }
                    </div> :
                        <div className="w-[360px] md:w-[700px] mx-auto">
                            <button className="bg-gray-light hidden text-blue-light ml-0 py-3 px-2 rounded-lg w-1/2 mt-4 mx-auto font-semibold flex md:hidden" onClick={() => {
                                window.history.back();
                            }}>
                                <ChevronLeftIcon className="w-5" /> Back</button>

                            <Combobox
                                onChange={(e: number | string) => {
                                    if (e === 0)
                                        setSearchYear(undefined);
                                    else
                                        setSearchYear(e.toString());
                                }}
                                value={(searchYear ? searchYear : "Any year") as any}

                            >
                                <Combobox.Input
                                    value={searchYear}
                                    onFocus={() => {
                                        // select all text on focus
                                        if (searchYear) {
                                            (document.activeElement as HTMLInputElement).setSelectionRange(0, searchYear.length);
                                        } else {
                                            (document.activeElement as HTMLInputElement).setSelectionRange(0, "Any year".length);
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            setSearchYear(undefined);
                                        } else {
                                            setSearchYear(e.target.value);
                                        }
                                    }}
                                    displayValue={(item: string) => {
                                        if (!item) {
                                            return "Any year"
                                        }
                                        return item;
                                    }}
                                    className="w-[150px] mt-4 ,b-4 mx-auto rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 self-start w-full md:block"
                                />
                                <Combobox.Options className="absolute z-10 mt-1 w-full max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <Combobox.Option key={0} value={0}
                                        className={({ active }) =>
                                            'relative cursor-default select-none py-2 pl-3 pr-9' + (active ? '' : 'text-gray-900')
                                        }>
                                        Any year
                                    </Combobox.Option>
                                    {
                                        filteredYears.map((year: any) => {
                                            return <Combobox.Option key={year.year} value={year.year} className={({ active }) =>
                                                'relative cursor-default select-none py-2 pl-3 pr-9' + (active ? '' : 'text-gray-900')
                                            }>
                                                {year.year}
                                            </Combobox.Option>
                                        })
                                    }
                                </Combobox.Options>
                            </Combobox>
                            <div className="justify-center text-center align-center">
                                {
                                    filteredYears.map((year: any) => {
                                        return <YearComponent key={year.year} year={year.year} image={year.image} />
                                    })
                                }
                            </div>
                        </div>
                }
                {
                    years.length === 0 && <div className="w-[700px] mx-auto justify-center text-center align-center">
                        <h1 className="text-3xl mb-8  text-center mt-8 font-bold">
                            No race found
                        </h1>
                    </div>
                }
            </div>
        </FadeIn >
    </div >;
}