import { useState } from "react";
import { login } from "../libs/http";
import toast from 'react-hot-toast';
import Input from "../components/Input";
import FormStructure from "../components/form";
import Button from "../components/button";
import NavBar from "../components/navBar";
import { Link } from "react-router-dom";

const LoginPage: React.FC = () => {
    const [form, setForm] = useState({
        email: "",
        password: ""
    });

    const handleLogin = () => {
        toast.promise(
            login(
                form.email,
                form.password
            ),
            {
                loading: 'Logging in...',
                success: () => {
                    setTimeout(() => {
                        window.location.href = "/years";
                    }, 1500);
                    return <b>Login success.</b>
                },
                error: <b>Login failed.</b>,
            }
        );
    }
    return <div className={`h-screen min-h-[70vh] bg-no-repeat bg-cover bg-bottom bg-none md:bg-[url('https://giunti-al-punto-cms.s3.eu-south-1.amazonaws.com/giraglia-straight.webp')]`}>
        <NavBar
            breadCrumb={[]}
            requireNotAuth={true}
            title="Giraglia Database"
        />
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
            }}
        >
            <FormStructure>
                <Input
                    type="email"
                    onChange={(e: any) => setForm({ ...form, email: e.target.value })}
                    placeholder="example@gmail.com"
                >
                    Enter your email
                </Input>
                <Input
                    type="password"
                    onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                    placeholder="Password"
                >
                    Enter your password
                </Input>
                <Button>
                    Login
                </Button>
                <p className="text-center mt-2">
                    You don't have an account? <Link to="/register" className="text-blue-500 underline">Sign up</Link>
                    <br></br>Did you forget your password? <Link to="/reset-password" className="text-blue underline">Reset password</Link>
                </p>
            </FormStructure>
        </form>
    </div>
}
export default LoginPage;
