import { useState } from "react";
import toast from "react-hot-toast";
import { login, register } from "../libs/http";
import NavBar from "../components/navBar";
import Input from "../components/Input";
import FormStructure from "../components/form";
import Button from "../components/button";
import { Link } from "react-router-dom";

const SignUpPage: React.FC = () => {
    const [step, setStep] = useState(0);
    const [form, setForm] = useState({
        name: "",
        surname: "",
        email: "",
        password: "",
        passwordConfirm: "",
        checkBox: false,
    });
    const errorsMessage = {
        register: "Error after registration, please login again.",
        errorPassword: "Password is not so strong.",
        errorPasswordConfirm: "Passwords do not match.",
        errorRegisterDefault: "Registration failed.",
    }
    const handleLogin = () => {
        try {
            login(
                form.email,
                form.password
            );
            return true;
        }
        catch (e) {
            setTimeout(() => {
                window.location.href = "/login";
            }, 2500);
            toast.error("Error after registration, please login again.");
        }
    }
    const handleSignUp = () => {
        if (!form.checkBox) {
            toast.error("You must agree with terms and conditions.");
            return;
        }
        if (form.email === "" || form.name === "" || form.surname === "" || form.password === "" || form.passwordConfirm === "") {
            toast.error("All fields are required.");
            return;
        }
        toast.promise(
            register(
                form.email,
                form.name + " " + form.surname,
                form.password,
                form.passwordConfirm,
            ),
            {
                loading: 'Logging in...',
                success: () => {
                    if (handleLogin()) {
                        setTimeout(() => {
                            window.location.href = "/years";
                        }, 1500);
                        return <b>Registration success.</b>
                    }
                    return <b>{errorsMessage.register}</b>
                },
                error: (e: any) => {
                    const { data: { data: { password, passwordConfirm } } } = e;
                    switch (password || passwordConfirm) {
                        case password:
                            return <b>{errorsMessage.errorPassword}</b>;
                        case passwordConfirm:
                            return <b>{errorsMessage.errorPasswordConfirm}</b>;
                        default:
                            return <b>{errorsMessage.errorRegisterDefault}</b>;
                    }
                },
            }
        );

    }
    return <div>
        <NavBar
            breadCrumb={[]}
            requireNotAuth={true}
            title="Giraglia Database"
        />
        {
            step === 0 ?
                <FormStructure>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        setStep(1);
                    }}
                        className="w-full"
                    >
                        <Input
                            onChange={(e: any) => setForm({ ...form, name: e.target.value })}
                            type="text"
                            placeholder="Mario"
                        >
                            Entery your name
                        </Input>
                        <Input
                            onChange={(e: any) => setForm({ ...form, surname: e.target.value })}
                            type="text"
                            placeholder="Rossi"
                        >
                            Enter your surname
                        </Input>
                        <button
                            type="submit"
                            className="w-4/5 text-lg mt-12 block mx-auto h-4 mb-5 text-white pb-9 pt-3 font-semi-bold bg-blue rounded-lg v-align-middle"
                            onClick={() => setStep(1)}
                        >
                            Next
                        </button>
                    </form>
                    <p className="text-center mt-2">
                        Have an account? <Link to="/login" className="text-blue underline">Login</Link>
                    </p>

                </FormStructure>
                :
                <FormStructure>
                    <Input
                        onChange={(e: any) => setForm({ ...form, email: e.target.value })}
                        type="email"
                        placeholder="Email"
                    >
                        Enter your email
                    </Input>
                    <Input
                        onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                        type="password"
                        placeholder="Password"
                    >
                        Enter your password
                    </Input>
                    <Input
                        onChange={(e: any) => setForm({ ...form, passwordConfirm: e.target.value })}
                        type="password"
                        placeholder="Confirm Password"
                    >
                        Confirm password
                    </Input>
                    <div className="flex justify-center items-center">
                        <input
                            type="checkbox"
                            className=""
                            onChange={(e: any) => setForm({ ...form, checkBox: e.target.checked })}
                        />
                        <span className="ml-2">
                            Agree with <Link to="https://www.rolexgiraglia.com/easyUsers/PrivacyPolicy.asp" className="text-blue underline">terms and conditions</Link>
                        </span>

                    </div>
                    <Button
                        onClick={() => handleSignUp()}
                    >
                        Sign up
                    </Button>
                    <p className="text-center mt-2">
                        Have an account? <Link to="/login" className="text-blue underline">Login</Link>
                    </p>
                </FormStructure>
        }
    </div>
}
export default SignUpPage;
