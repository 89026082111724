export default function Loader(props: { lineNumbers: number, className?: string }) {
    return <div role="status" className="animate-pulse mb-6">
        {
            Array.from(Array(props.lineNumbers).keys()).map((item, index) => {
                return <div
                    key={index}
                    className={"h-2 bg-gray-100 rounded-full dark:bg-gray-300 mb-2.5 " + props.className} />
            })
        }
    </div>
}