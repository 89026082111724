import { Link } from "react-router-dom";
import { checkLogin, logout } from "../libs/http";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Menu } from "@headlessui/react";

const Navbar = ({ userName, breadCrumb, requireAuth = false, requireNotAuth = false, title = "", mobileBg = 2 }: { userName?: string, breadCrumb: any, requireAuth?: boolean, requireNotAuth?: boolean, title?: string, mobileBg?: 1 | 2 }) => {
    const [userData, setUserData] = useState<any>(null);
    useEffect(() => {
        checkLogin().then((res: any) => {
            setUserData(res);
            if (requireNotAuth && res !== null) {
                window.location.href = "/years";
            } else if (requireAuth && res === null) {
                toast.error("You are not logged in.");
                window.location.href = "/login";
            }
        }).catch((err) => {
            console.log(err);
        })
    }, []);
    return (
        <nav className={`bg-boat-${mobileBg} bg-cover bg-center py-1 md:bg-none md:bg-blue md:py-4 px-4`}>
            <div className="bg-boat-2 bg-boat-1 hidden"></div> {/* This is a hack to make the background loaded */}
            <div className="relative  py-10 md:py-4 bg-opacity-40 bg-no-repeat md:bg-none flex justify-between items-start md:items-center">
                {!userName && <div className="md:flex md:gap-x-12 hidden">
                    <span className="text-white text-md mr-4">{
                        breadCrumb.map((item: any, index: number) => {
                            return <Link
                                key={item.link}
                                to={item.link}
                                className={index === breadCrumb.length - 1 ? "text-white text-md mr-4" : "cursor-pointer text-gray-400 text-md mr-2"}>{item.name} {index !== breadCrumb.length - 1 && "/"}</Link>
                        }
                        )
                    }</span>
                </div>}
                <div className="absolute" style={{ left: "50%", transform: "translateX(-50%)" }}>
                    <img src={"/logo_yci_4.png"} alt="Logo" className="w-[155px] align-center" />
                </div>
                {
                    title && <div className="md:hidden mt-32">
                        <span className={"text-white " + (mobileBg === 2 ? "text-6xl" : "text-4xl")}>{title}</span>
                    </div>
                }
                <div className="flex flex-row md:items-center">
                    <span className="text-white sm:text-sm lg:text-md hidden sm:inline mr-4">{
                        userData ? userData?.name : <Link to="/login">Accedi</Link>
                    }</span>
                    {userData && <Menu as="div" className="relative hidden md:inline-block text-left">
                        <Menu.Button>
                            <img src={"/user.svg"} alt="Logo" className="w-[15px] align-center" />
                        </Menu.Button>
                        <Menu.Items className="absolute left-0 md:right-0 md:left-full w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 ">
                                <Menu.Item>
                                    <button onClick={logout} className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left">
                                        <span>Logout</span>
                                    </button>
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Menu>}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
