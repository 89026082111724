


/* eslint-disable eqeqeq */
/* dependencies */
import { useEffect, Fragment, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from 'react-select';
import FadeIn from 'react-fade-in';

/* libs */
import { getParticipants } from "../libs/http";
import { IPartecipant } from "../libs/interfaces";
import { DownActive, DownInactive, UpActive } from "../components/icons";
/* components */
import NavBar from "../components/navBar";
import Loader from "../components/loader";
import MultiRangeSlider from "../components/rangeSlider";
import { TimeKind, getMaxAndMinTime, getParticipantsInTimeRange, participantToDisplayable } from "../libs/timeConvert";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, XMarkIcon } from "@heroicons/react/20/solid";

const FilterIcon = () => <svg width="18" height="12" viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 24.5H22V20.5H14V24.5ZM0 0.5V4.5H36V0.5H0ZM6 14.5H30V10.5H6V14.5Z" fill="#7F929E" />
</svg>

const Th = (props: any) => <th scope="col"
    onClick={() => {
        props.setSortHandler(props.sortParam);
    }}
    className="px-3 py-4 text-left text-sm cursor-pointer">
    {props.children}
    {props.sort?.includes(props.sortParam) ? (
        props.sort.includes('-') ?
            <DownActive /> :
            <UpActive />
    ) :
        <DownInactive />
    }
</th>

const SelectComponent = (props: any) => <Select
    className={props.className == "mobile" ? "basic-mobile" : "basic-single "}
    classNamePrefix="select"
    defaultValue={props.defaultValue}
    isLoading={props.loading}
    onInputChange={props.onInputChange}
    isSearchable={true}
    name="color"
    options={props.options}
    onChange={props.onChange}
/>

const MobileParticipant = (props: any) => {
    const [isOpen, setIsOpen] = useState(false)
    return <div key={props.index} className="bg-gray-200 py-4 px-6 mx-10 rounded-xl mb-4" onClick={() => setIsOpen(!isOpen)}>
        <span className="flex items-center space-x-3">
            {isOpen ?
                <svg className="inline min-w-[15px] min-h-[15px]" width="15" height="15" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.25 0.25L7.75 0.25L4 7.2865L0.25 0.25Z" fill="#00263E" />

                </svg>
                : <svg className="inline min-w-[15px] min-h-[15px]" width="15" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.75 7.875L0.25 0.838543L0.25 14.9115L7.75 7.875Z" fill="#00263E" />
                </svg>
            }

            <span className={
                props.partecipant.position == 1 ? "mr-4 text-xl font-bold text-yellow-500" :
                    props.partecipant.position == 2 ? "mr-4 text-xl font-bold text-gray-500" :
                        props.partecipant.position == 3 ? "mr-4 text-xl font-bold text-yellow-900" :
                            "mr-4 text-xl font-bold"}>{props.partecipant.position == 0 ? "-" : props.partecipant.position}</span>
            <span className="text-lg font-bold">{props.partecipant.boat}</span>
            <span className="
                            text-sm
                                align-end
                            ">{props.partecipant.vehicleNumber.split(' ')[0]}</span></span>
        {!isOpen && <div className="flex justify-between">

            <span className="inline whitespace-nowrap px-3 py-2 text-sm text-gray-500">{props.partecipant.shipOwner}</span>
            <span className="inline whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                {props.partecipant.hours_compensed}h {" "}
                {props.partecipant.minutes_compensed < 10 ? `0${props.partecipant.minutes_compensed}` : props.partecipant.minutes_compensed}'{" "}
                {props.partecipant.seconds_compensed < 10 ? `0${props.partecipant.seconds_compensed}` : props.partecipant.seconds_compensed}''{" "}
            </span>
        </div>
        }
        <div>
            {isOpen && [
                { label: "N.Velico", value: props.partecipant.vehicleNumber },
                { label: "Owner", value: props.partecipant.shipOwner },
                { label: "Class Velica", value: props.partecipant.class },
                { label: "Real Time", value: participantToDisplayable(props.partecipant, TimeKind.real) },
                { label: "Compensated time", value: participantToDisplayable(props.partecipant, TimeKind.compensed) },
            ].map((item, index) => (
                <div className="flex justify-between" key={index}>
                    <span className="inline whitespace-nowrap px-3 py-2 text-xs text-blue font-bold">{item.label}</span>
                    <span className="inline whitespace-nowrap px-3 py-2 text-xs text-right text-blue-light">{item.value}</span>
                </div>
            ))}

        </div>
    </div>;

}


export default function Year(props: any) {
    const location = useLocation();
    const year = location.pathname.split("/")[2];

    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [sortModal, setSortModal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [sort, setSort] = useState<string | null>(null);

    const [partecipants, setPartecipants] = useState<IPartecipant[]>([]);
    const [partecipantsOrig, setPartecipantsOrig] = useState<IPartecipant[]>([]);
    const [uniques, setUniques] = useState<any>({});
    const [filters, setFilters] = useState<any>({});
    const [compensedMin, setCompensedMin] = useState<number>(0);
    const [compensedMax, setCompensedMax] = useState<number>(0);
    const [showCompensedFilter, setShowCompensedFilter] = useState<boolean>(false);
    const [realMin, setRealMin] = useState<number>(0);
    const [realMax, setRealMax] = useState<number>(0);
    const [showRealFilter, setShowRealFilter] = useState<boolean>(false);

    const setSortHandler = (sortParams: string) =>
        setSort(sort == `+${sortParams}` ? `-${sortParams}` : `+${sortParams}`);

    useEffect(() => {
        let partecipantToSort = partecipants;
        let partecipantWithZero = [] as any;
        if (sort && sort == "+position") {
            partecipantToSort = partecipantToSort.filter((item: any) => item.position != 0);
            partecipantWithZero = partecipants.filter((item: any) => item.position == 0);
        }

        const filteredPartecipants = partecipantToSort.sort((a: any, b: any) => {
            if (sort == null) return 1;
            const sortParam = sort.split("+")[1] || sort.split("-")[1];
            const sortDirection = sort.includes('-') ? -1 : 1;
            return a[sortParam] > b[sortParam] ? sortDirection : -sortDirection;
        });

        if (sort && sort == "+position") {
            //Sort by -hours_compensed if != 0 only for partecipantWithZero
            partecipantWithZero = partecipantWithZero.sort((a: any, b: any) => {
                return a["hours_compensed"] > b["hours_compensed"] ? -1 : 1;
            });
            setPartecipants([...filteredPartecipants, ...partecipantWithZero]);
            return;
        }
        setPartecipants([...filteredPartecipants]);
    }, [sort])


    useEffect(() => {
        let filteredPartecipants = [] as any;
        let validKeys = Object.keys(filters).filter((key: any) => {
            const filter = filters[key];
            return filter != null && filter != "" && filter != "null";
        });
        // filter by time real and compensed
        filteredPartecipants = getParticipantsInTimeRange(partecipantsOrig, realMin, realMax, TimeKind.real);
        filteredPartecipants = getParticipantsInTimeRange(filteredPartecipants, compensedMin, compensedMax, TimeKind.compensed);
        if (validKeys.length == 0) {
            setPartecipants(filteredPartecipants);
            return;
        }
        validKeys.forEach((key: any) => {
            const filter = filters[key];
            if (parseInt(year) >= 2009 && key == "class") {
                filteredPartecipants.push(partecipantsOrig.filter((partecipant: any) =>
                    partecipant[key].includes(filters[key])));
            }
            else {
                filteredPartecipants.push(partecipantsOrig.filter((partecipant: any) =>
                    partecipant[key] == filter
                ));
            }
        })
        let filteredResult = filteredPartecipants.flat();
        filteredResult = filteredResult.filter((item: any) => {
            return validKeys.every((key: any) => {
                if (parseInt(year) >= 2009 && key == "class") {
                    return item[key].includes(filters[key]);
                }
                const filter = filters[key];
                return item[key] == filter;
            }
            )
        })
        const unique = [...new Set(filteredResult) as any];
        setPartecipants(unique);
    }, [filters, realMin, realMax, compensedMin, compensedMax])

    const fetchPartecipants = async () => {
        getParticipants(year).then((res) => {
            const partecipants = res.items as unknown as IPartecipant[];
            const firstClass = partecipants.map((item: any) => item.class != null ? item.class : "").filter((item: any) => item != "");
            setPartecipantsOrig(partecipants);
            const uniques: any = {};
            Object.keys(partecipants[0]).forEach((key: any) => {
                const set = new Set(partecipants.map((item: any) => item[key])) as any;
                uniques[key] = [...set as any];
            });
            Object.keys(uniques).forEach((key: any) => {
                uniques[key] = uniques[key].map((item: any) => ({ value: item, label: item == "" ? "Nessun valore" : item }));
                // remove "Nessun valore" from class
                uniques[key] = uniques[key].filter((item: any) => item.value != "");
            });
            const { min: realmin, max: realmax } = getMaxAndMinTime(partecipants, TimeKind.real);
            setRealMin(realmin);
            setRealMax(realmax);
            const { min: compmin, max: compmax } = getMaxAndMinTime(partecipants, TimeKind.compensed);
            setCompensedMin(compmin);
            setCompensedMax(compmax);
            if (parseInt(year) < 2009) {
                setPartecipants(partecipants.filter((item: any) => item.class == firstClass[0]));
                setFilters({ class: firstClass[0] });
            }
            else {
                console.log(uniques["class"], "uniques class")
                uniques["class"] = uniques["class"].reduce((val: any, item: any) => {
                    if (item.value.includes("ORC") && !val.some((item: any) => item.value == "ORC")) {
                        val.push({
                            value: "ORC",
                            label: "ORC"
                        });
                    }
                    if (item.value.includes("IRC") && !val.some((item: any) => item.value == "IRC")) {
                        val.push({
                            value: "IRC",
                            label: "IRC"
                        });
                    }
                    if (item.value.includes("IMA") && !val.some((item: any) => item.value == "IMA")) {
                        val.push({
                            value: "IMA",
                            label: "IMA"
                        });
                    }
                    return val;
                }, []);
                const firstClass = uniques["class"][0].value;
                setFilters({ class: firstClass });
                setPartecipants(partecipants);
            }
            console.log(uniques);
            setUniques(uniques);
            setLoading(false);
            setTimeout(() => {
                setSort("+position");
            }, 1000);

        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        fetchPartecipants();
    }, [])

    return <div>
        <NavBar
            breadCrumb={[
                { name: "Home", link: "/" },
                { name: "Seleziona Ricerca", link: "/years" },
                { name: year, link: location.pathname }
            ]}
            title={year}
            mobileBg={1}
            requireAuth={true}
        ></NavBar>

        <div className="container mx-auto">
            <FadeIn visible={!loading} className="hidden md:block">
                <div className="flex justify-between items-center mt-10 mb-6">
                    <div className="w-1/2">
                        <h2
                            className="text-4xl font-bold text-gray-800 "
                        >Giraglia {year}</h2>
                        {loading ? <Loader lineNumbers={1} className="w-[95px]" /> : <div className="">
                            <span className="text-gray-800 text-sm">
                                {partecipants.length} partecipanti
                            </span>
                        </div>}
                    </div>
                    <div className="flex w-[400px] justify-center bg-gray-100 rounded-lg p-6">
                        <div className="flex">
                            <div className="mr-4 h-6 w-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" viewBox="0 0 29 32" fill="none">
                                    <path d="M18.2942 3.76471L17.5413 0H0.600098V32H4.3648V18.8235H14.906L15.6589 22.5882H28.8354V3.76471H18.2942Z" fill="#1A3C52" />
                                </svg>
                            </div>
                            <span className="text-gray-800 text-sm">
                                Segnala eventuali anomalie o dati mancanti
                            </span>
                            <button
                                onClick={
                                    () => {
                                        if (typeof window !== 'undefined')
                                            window.location.href = "mailto:info@rolexgiraglia.com";
                                    }
                                }
                                className="ml-4 bg-blue hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-lg h-10"
                            >
                                Scrivici
                            </button>
                        </div>
                    </div>
                </div>
                {loading ? <Loader lineNumbers={1} /> : <div className="flex justify-between items-center">
                    {(parseInt(year) >= 2009 || parseInt(year) <= 2001) &&
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={{ value: filters.class, label: filters.class }}
                            isLoading={loading}
                            onChange={(e: any) => {
                                setFilters({ ...filters, class: e.value });
                                setSort(null);
                                setTimeout(() => {
                                    setSort("+position");
                                }, 1000);
                            }}
                            isSearchable={true}
                            name="color"
                            options={[
                                { value: 'null', label: 'Class' },
                                ...Array.from(uniques["class"]) as any
                            ]}
                        />}
                    <div>

                    </div>
                    <div className="flex gap-4 items-center">
                        <FilterIcon />
                        <SelectComponent
                            onChange={(e: any) => {
                                setFilters({ ...filters, nation: e.value });
                            }}
                            value={filters.nation}
                            defaultValue={{ value: 'null', label: 'Nation' }}
                            options={uniques["nation"]}
                        />
                        <SelectComponent
                            onChange={(e: any) => {
                                setFilters({ ...filters, boat: e.value });
                            }}
                            value={filters.boat}
                            defaultValue={{ value: 'null', label: 'Boat Name' }}
                            options={[
                                { value: 'null', label: 'Boat Name' },
                                ...Array.from(uniques["boat"])]}
                        />
                        <SelectComponent
                            onChange={(e: any) => {
                                setFilters({ ...filters, vehicleNumber: e.value });
                            }}
                            value={filters.vehicleNumber}
                            defaultValue={{ value: 'null', label: 'Sail Number' }}
                            options={
                                [
                                    { value: 'null', label: 'Sail Number' },
                                    ...Array.from(uniques["vehicleNumber"])]}
                        />
                        <SelectComponent
                            onChange={(e: any) => {
                                setFilters({ ...filters, shipOwner: e.value });
                            }}
                            value={filters.shipOwner}
                            defaultValue={{ value: 'null', label: 'Owner' }}
                            options={[
                                { value: 'null', label: 'Owner' },
                                ...Array.from(uniques["shipOwner"])]}
                        />
                        <div className="relative inline-block text-left">
                            <button
                                onClick={() => {
                                    setShowRealFilter(!showRealFilter);
                                }}
                                className="py-2 min-w-[250px] z-20 relative px-4 bg-blue text-white text-left text-sm cursor-pointer rounded-lg"
                            >
                                Real Time
                            </button>
                            <MultiRangeSlider
                                hidden={!showRealFilter}
                                participants={partecipantsOrig}
                                originalMax={realMax}
                                originalMin={realMin}
                                set={TimeKind.real}
                                onChange={(e) => {
                                    const { min, max } = e;
                                    setRealMin(min);
                                    setRealMax(max);
                                }}
                            />
                        </div>
                        <div className="relative inline-block text-left">

                            <button
                                onClick={() => {
                                    setShowCompensedFilter(!showCompensedFilter);
                                }}
                                className="py-2 min-w-[250px] z-20 relative px-4 bg-blue text-white text-left text-sm cursor-pointer rounded-lg"
                            >
                                Compensated time
                            </button>
                            <MultiRangeSlider
                                hidden={!showCompensedFilter}
                                participants={partecipantsOrig}
                                set={TimeKind.compensed}
                                originalMax={compensedMax}
                                originalMin={compensedMin}
                                onChange={(e) => {
                                    const { min, max } = e;
                                    setCompensedMin(min);
                                    setCompensedMax(max);
                                }}
                            />
                        </div>

                    </div>
                </div>}
                {loading ? <Loader lineNumbers={8} /> : partecipants.length === 0 ? <div className="mt-10">
                    <h2 className="text-2xl font-bold text-gray-800 ">
                        Nessun risultato, con i filtri seguenti filtri
                        <p className="text-sm text-gray-400 font-normal mb-4">
                            Clicca su un filtro per rimuoverlo
                        </p>
                        {Object.keys(filters).map((key) => {
                            if (filters[key] != "null")
                                return <span className="font-bold text-sm text-gray-900 mr-4 bg-gray-200 rounded-lg p-2">
                                    <button
                                        onClick={() => {
                                            setFilters({ ...filters, [key]: "null" });
                                        }}
                                    >
                                        <span className="underline">{filters[key]}</span>
                                    </button>
                                </span>
                        })
                        }
                    </h2>
                </div> :
                    <div className="-mx-4 mt-10  sm:mx-0 sm:rounded-lg shadow-lg">
                        <table className="min-w-full divide-y divide-gray-300 rounded-lg p-8 ">
                            <thead className="bg-blue text-white font-bold">
                                <tr>
                                    <Th
                                        sortParam="position"
                                        sort={sort}
                                        setSortHandler={setSortHandler}
                                    >
                                        Ranking
                                    </Th>
                                    {parseInt(year) > 2001 && <Th
                                        sortParam="boat"
                                        sort={sort}
                                        setSortHandler={setSortHandler}
                                    >
                                        Class
                                    </Th>}
                                    <Th
                                        sortParam="boat"
                                        sort={sort}
                                        setSortHandler={setSortHandler}
                                    >
                                        Boat Name
                                    </Th>
                                    <Th
                                        sortParam="shipOwner"
                                        sort={sort}
                                        setSortHandler={setSortHandler}
                                    >
                                        Owner
                                    </Th>
                                    <Th
                                        sortParam="nation"
                                        sort={sort}
                                        setSortHandler={setSortHandler}
                                    >
                                        Nation
                                    </Th>
                                    <Th
                                        sortParam="vehicleNumber"
                                        sort={sort}
                                        setSortHandler={setSortHandler}
                                    >
                                        Sail Number
                                    </Th>
                                    <Th
                                        sortParam="hours_real"
                                        sort={sort}
                                        setSortHandler={setSortHandler}
                                    >
                                        Real Time
                                    </Th>
                                    <Th
                                        sortParam="hours_compensed"
                                        sort={sort}
                                        setSortHandler={setSortHandler}
                                    >
                                        Compensated time
                                    </Th>

                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {partecipants.map((partecipant, index) => <tr key={index}>
                                    <th scope="row">{partecipant.position == 0 ? "-" : partecipant.position}</th>
                                    {parseInt(year) > 2001 && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{partecipant.class}</td>}
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{partecipant.boat}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{partecipant.shipOwner}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{partecipant.vehicleNumber.split(' ')[0]}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{partecipant.vehicleNumber.split(' ')[1]}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {partecipant.hours_real}h {" "}
                                        {partecipant.minutes_real < 10 ? `0${partecipant.minutes_real}` : partecipant.minutes_real}'{" "}
                                        {partecipant.seconds_real < 10 ? `0${partecipant.seconds_real}` : partecipant.seconds_real}''{" "}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {partecipant.hours_compensed}h {" "}
                                        {partecipant.minutes_compensed < 10 ? `0${partecipant.minutes_compensed}` : partecipant.minutes_compensed}'{" "}
                                        {partecipant.seconds_compensed < 10 ? `0${partecipant.seconds_compensed}` : partecipant.seconds_compensed}''{" "}
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                        <br />
                        <div className="mb-12" />
                    </div>}
            </FadeIn>
            <FadeIn visible={!loading} className="block md:hidden">
                <div className="block justify-between items-center mt-4 mb-6">
                    <div className="ml-4 mb-4">
                        <Link
                            to="/years"
                            className="bg-gray-light text-blue-light mr-6 ml-0 py-3 px-2 rounded-lg w-[120px] mt-4 mx-auto font-semibold flex md:hidden" onClick={() => { }}>
                            <ChevronLeftIcon className="w-5" />
                            Indietro
                        </Link>
                    </div>

                    <div className="flex  justify-center bg-gray-100 rounded-lg p-6 px-12">
                        <div className="flex">
                            <span className="text-gray-800 text-sm">
                                Report any anomalies or missing data!
                            </span>
                            <button
                                onClick={
                                    () => {
                                        if (typeof window !== 'undefined')
                                            window.location.href = "mailto:info@rolexgiraglia.com";
                                    }
                                }
                                className="w-1/2 ml-4 bg-blue hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-lg h-10"
                            >
                                Contact us
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex gap-4 items-center mx-auto align-middle w-full justify-center mb-8">
                    <div onClick={() => {
                        const uniqueIndex = uniques["class"].findIndex((el: {
                            value: string
                            label: string
                        }) => el.value == filters.class);
                        setFilters({
                            ...filters,
                            class: uniques["class"][(uniqueIndex > 0) ? ((uniqueIndex - 1) % uniques["class"].length) : (uniques["class"].length - 1)].value
                        })
                        setSort(null);
                        setTimeout(() => {
                            setSort("+position");
                        }, 500);
                    }}>
                        <svg width="6" height="13" viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.84124e-07 6.5L6 0.870835L6 12.1292L2.84124e-07 6.5Z" fill="#00263E" />
                        </svg>
                    </div>
                    <span className="bg-gray-100 rounded-lg p-2 px-4 text-gray-800 text-lg font-bold">
                        {
                            filters.class !== "null" ? filters.class : "Tutte le classi"
                        }
                    </span>
                    <div onClick={() => {
                        const uniqueIndex = uniques["class"].findIndex((el: {
                            value: string
                            label: string
                        }) => el.value == filters.class);
                        setFilters({
                            ...filters,
                            class: uniques["class"][(uniqueIndex + 1) % uniques["class"].length].value
                        })
                        setSort(null);
                        setTimeout(() => {
                            setSort("+position");
                        }, 500);
                    }}>                    <svg width="6" height="13" viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 6.5L-5.30183e-07 0.870835L-3.80654e-08 12.1292L6 6.5Z" fill="#00263E" />
                        </svg>
                    </div>
                </div>
                <div className="flex mx-6">
                    <div className="w-1/2 text-center">
                        <button onClick={() => setFilterModal(true)} className="rounded-md py-2 text-white bg-red-700 w-[130px]" style={{ backgroundColor: "#1A3C52" }}>
                            Filter
                            <svg className="inline ml-2" width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.33333 16H14.6667V13.3333H9.33333V16ZM0 0V2.66667H24V0H0ZM4 9.33333H20V6.66667H4V9.33333Z" fill="white" />
                            </svg>
                        </button>
                    </div>
                    <div className="w-1/2 text-center hidden">
                        <button onClick={() => setFilterModal(true)} className="rounded-md py-2 text-white bg-red-700 w-[130px]" style={{ backgroundColor: "#1A3C52" }}>
                            Order
                            <svg className="inline ml-2" width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.125 8L14.1615 0.5H0.0885434L7.125 8Z" fill="white" />
                            </svg>
                        </button>
                    </div>
                </div>
                <Transition.Root show={filterModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => setFilterModal(false)}>
                        <div className="fixed inset-0" />
                        <div className="fixed inset-0 overflow-hidden">
                            <div className="absolute inset-0 overflow-hidden">
                                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full ">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="translate-y-full"
                                        enterTo="translate-y-[30vh]"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="translate-y-0"
                                        leaveTo="translate-y-full"
                                    >
                                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md bg-white border border-1 rounded-lg">
                                            <div className="flex h-full flex-col overflow-y-scroll  py-6 shadow-xl">
                                                <div className="px-4 sm:px-6">
                                                    <div className="flex items-start justify-between">
                                                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 w-full">
                                                            <hr
                                                                className="hidden border-2 border-black w-[80px] mx-auto"
                                                            />
                                                        </Dialog.Title>
                                                        <div className="ml-3 flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                onClick={() => setFilterModal(false)}
                                                            >
                                                                <span className="sr-only">Close panel</span>
                                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!loading && <div className="relative mt-0 flex-1 px-4 sm:px-6">
                                                    <p className="font-bold mb-6">Apply Filters</p>
                                                    <SelectComponent
                                                        className="mobile"
                                                        onChange={(e: any) => {
                                                            setFilters({ ...filters, nation: e.value });
                                                        }}
                                                        value={{ value: filters.nation, label: filters.nation }}
                                                        defaultValue={filters.nation ? { value: filters.nation, label: filters.nation } : { value: 'null', label: 'Nation' }}
                                                        options={uniques["nation"]}
                                                    />
                                                    <SelectComponent
                                                        className="mobile"
                                                        onChange={(e: any) => {
                                                            setFilters({ ...filters, vehicleNumber: e.value });
                                                        }}
                                                        value={{ value: filters.vehicleNumber, label: filters.vehicleNumber }}
                                                        defaultValue={filters.vehicleNumber ? { value: filters.vehicleNumber, label: filters.vehicleNumber } : { value: 'null', label: 'Sail Number' }}
                                                        options={
                                                            [
                                                                { value: 'null', label: 'Sail Number' },
                                                                ...Array.from(uniques["vehicleNumber"])]}
                                                    />
                                                    <SelectComponent
                                                        className="mobile"
                                                        onChange={(e: any) => {
                                                            setFilters({ ...filters, shipOwner: e.value });
                                                        }}
                                                        value={{ value: filters.shipOwner, label: filters.shipOwner }}
                                                        defaultValue={filters.shipOwner ? { value: filters.shipOwner, label: filters.shipOwner } : { value: 'null', label: 'Owner' }}
                                                        options={[
                                                            { value: 'null', label: 'Owner' },
                                                            ...Array.from(uniques["shipOwner"])]}
                                                    />
                                                    <div className="relative inline-block text-left w-full mt-2">
                                                        <button
                                                            onClick={() => {
                                                                setShowRealFilter(!showRealFilter);
                                                            }}
                                                            className="py-2 min-w-[250px] w-full relative border border-gray-300 border-1 px-2 md:px-4 bg-white text-black text-left text-sm cursor-pointer rounded-md"
                                                        >
                                                            Real Time
                                                        </button>
                                                        <MultiRangeSlider
                                                            hidden={!showRealFilter}
                                                            participants={partecipantsOrig}
                                                            originalMax={realMax}
                                                            originalMin={realMin}
                                                            set={TimeKind.real}
                                                            onChange={(e) => {
                                                                const { min, max } = e;
                                                                setRealMin(min);
                                                                setRealMax(max);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="relative inline-block text-left w-full mt-2">

                                                        <button
                                                            onClick={() => {
                                                                setShowCompensedFilter(!showCompensedFilter);
                                                            }}
                                                            className="py-2 min-w-[250px] w-full relative border border-gray-300 border-1 px-2 md:px-4 bg-white text-black text-left text-sm cursor-pointer rounded-md"
                                                        >
                                                            Compensated time
                                                        </button>
                                                        <MultiRangeSlider
                                                            hidden={!showCompensedFilter}
                                                            participants={partecipantsOrig}
                                                            set={TimeKind.compensed}
                                                            originalMax={compensedMax}
                                                            originalMin={compensedMin}
                                                            onChange={(e) => {
                                                                const { min, max } = e;
                                                                setCompensedMin(min);
                                                                setCompensedMax(max);
                                                            }}
                                                        />
                                                    </div>
                                                </div>}
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
                {loading ? <Loader lineNumbers={8} /> : partecipants.length === 0 ? <div className="mt-10">
                    <h2 className="text-2xl font-bold text-gray-800 px-12 md:px-0">
                        No result found with this filters
                        <p className="text-sm text-gray-400 font-normal mb-4">
                            Click on a filter to remove it
                        </p>
                        {Object.keys(filters).map((key) => {
                            if (filters[key] != "null")
                                return <span className="font-bold text-sm text-gray-900 mr-4 bg-gray-200 rounded-lg p-2">
                                    <button
                                        onClick={() => {
                                            setFilters({ ...filters, [key]: "null" });
                                        }}
                                    >
                                        <span className="underline">{filters[key]}</span>
                                    </button>
                                </span>
                        })
                        }
                    </h2>
                </div> :
                    <div className="mt-10  sm:mx-0 sm:rounded-lg">
                        {partecipants.map((partecipant, index) => <MobileParticipant key={index} partecipant={partecipant} />)}
                        <div className="mb-12" />
                    </div>}
            </FadeIn>
        </div >
    </div >
}