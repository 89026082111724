export const UpInactive = ({ className }: any) => <span className={"ml-1 inline-flex rounded-md shadow-sm align-middle " + className}>
    <img src={"/icons/UpInactive.svg"} alt="" className="" ></img>
</span>

export const UpActive = ({ className }: any) => <span className={"ml-1 inline-flex rounded-md shadow-sm align-middle " + className}>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10.5" r="10" fill="#FA9507" />
        <path d="M10 4.5L15.1962 10.5H4.80389L10 4.5Z" fill="white" />
        <path d="M10 16.5L15.1962 10.5H4.80389L10 16.5Z" fill="white" fillOpacity="0.5" />
    </svg>
</span >
//downInactive and downActive are the same as upInactive and upActive but 180° rotated
export const DownInactive = ({ className }: any) => <UpInactive className={className + " transform rotate-180"} />
export const DownActive = () => <UpActive className="transform rotate-180" />
