import { HomePage } from "./pages/home"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LoginPage from "./pages/login";
import SignUpPage from "./pages/signUp";
import Years from "./pages/years";
import Year from "./pages/[year]";
import { Toaster } from "react-hot-toast";
import ResetPasswordPage from "./pages/resetPassword";
import ConfirmResetPage from "./pages/confirmReset";
import { useEffect, useState } from "react";
import { checkLogin, logout } from "./libs/http";

function App() {
  const [userData, setUserData] = useState<any>(null);
  useEffect(() => {
    checkLogin().then((res: any) => {
      setUserData(res);
    }).catch((err) => {
      console.log(err);
    })
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/login" Component={LoginPage} />
          <Route path="/register" Component={SignUpPage} />
          <Route path="/reset-password" Component={ResetPasswordPage} />
          <Route path="/reset-password/confirm" Component={ConfirmResetPage} />
          <Route path="/years" Component={Years} />
          <Route path="/years/:year" Component={Year} />
        </Routes>
      </BrowserRouter>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 3000,
          },
        }}
      />

      <div>
        {
          userData && <div className="bottom-0 text-center right-0 p-4 md:hidden mt-8">
            <button
              onClick={() => {
                logout();
                window.location.href = "/login";
              }}
              className="text-white rounded-lg text-md mr-4 underline bg-blue px-4 py-2">
              Logout
            </button>
          </div>
        }
      </div>
    </div>
  );
}

export default App;
