
import { useState } from "react";
import toast from "react-hot-toast";
import { confirmResetPassword } from "../libs/http";
import Input from "../components/Input";
import NavBar from "../components/navBar";
import FormStructure from "../components/form";
import Button from "../components/button";

const ConfirmResetPage: React.FC = () => {
    // get token from querystring
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const [step, setStep] = useState(0);
    const [form, setForm] = useState({
        password: "",
        passwordConfirm: "",
    });
    const errorsMessage = {
        errorPassword: "Password is not so strong.",
        errorPasswordConfirm: "Passwords do not match.",
        errorRegisterDefault: "Registration failed.",
    }

    const handleConfirmReset = () => {
        toast.promise(
            confirmResetPassword(
                token as string,
                form.password,
                form.passwordConfirm,
            ),
            {
                loading: 'Resetting password...',
                success: () => {
                    setTimeout(() => {
                        window.location.href = "/years";
                    }, 1500);
                    return <b>Password reset!</b>;
                },
                error: (e: any) => {
                    const { data: { data: { password, passwordConfirm } } } = e;
                    console.log(password, passwordConfirm);
                    switch (password || passwordConfirm) {
                        case password:
                            return <b>{errorsMessage.errorPassword}</b>;
                        case passwordConfirm:
                            return <b>{errorsMessage.errorPasswordConfirm}</b>;
                        default:
                            return <b>{errorsMessage.errorRegisterDefault}</b>;
                    }
                },
            }
        );

    }
    return <div>
        <NavBar breadCrumb={[]}
            title="Giraglia Database" />
        <FormStructure>
            <Input
                type="password"
                onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                placeholder="Password">
                Enter your new password
            </Input>
            <Input
                type="password"
                onChange={(e: any) => setForm({ ...form, passwordConfirm: e.target.value })}
                placeholder="Confirm Password">
                Confirm your new password
            </Input>
            <Button
                onClick={() => handleConfirmReset()}
            >
                Reset Password
            </Button>
        </FormStructure>
    </div>
}
export default ConfirmResetPage;
