import PocketBase, { LocalAuthStore } from 'pocketbase';

const baseAuthStore = new LocalAuthStore();
const pb = new PocketBase('https://regate.stagetest.uk/', baseAuthStore);


const login = async (email: string, password: string) => {
    const userData = await pb.collection('users').authWithPassword(email, password, {}, {});
    console.log(userData);
    console.log(pb.authStore.isValid);
    console.log(pb.authStore.token);
    console.log(pb.authStore?.model?.id);
    return userData;
}

const checkLogin = async () => {
    console.log(pb.authStore.model);
    return await pb.authStore.model
}

const logout = async () => {
    await pb.authStore.clear();
    window.location.replace('/');
}

const requestResetPassword = async (email: string) => {
    const result = await pb.collection("users").requestPasswordReset(email, {}, {});
    console.log(result);
}

const confirmResetPassword = async (resetToken: string, newPassword: string, newPasswordConfirm: string) => {
    const result = await pb.collection("users").confirmPasswordReset(resetToken, newPassword, newPasswordConfirm, {}, {});
    console.log(result);
    return result;
}

const register = async (email: string, userName: string, password: string, passwordConfirm: string) => pb.collection('users').create({
    email,
    name: userName,
    password,
    passwordConfirm,
}, {});

const getRaces = async (page: number = 1, perPage: number = 30, queryParams: any = {
    sort: '-year',
}) => pb.collection("races").getList(page, perPage, queryParams);

const getParticipants = async (year: any) => pb.collection("participants").getList(1, 1000, {
    filter: `race.year = "${year}"`,
    sort: '+position'
});

export {
    checkLogin,
    login,
    logout,
    register,
    requestResetPassword,
    confirmResetPassword,
    getRaces,
    getParticipants,
}
