import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IPartecipant } from "../libs/interfaces";
import { TimeKind, getMaxAndMinTime, secondsToDisplayable } from "../libs/timeConvert";
import { Disclosure } from '@headlessui/react'
import Input from "./Input";

const MultiRangeSlider = (
    { participants, set, onChange, originalMax, originalMin, hidden = false }: {
        participants: IPartecipant[],
        set: TimeKind,
        originalMin: number,
        originalMax: number,
        hidden?: boolean,
        onChange: ({ min, max }: { min: number, max: number }) => void
    }) => {
    const { min, max } = useMemo(() => {
        return getMaxAndMinTime(participants, set);
    }, [participants, set]);
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(min);
    const maxValRef = useRef(max);
    const range = useRef<HTMLDivElement>(null);


    // Convert to percentage
    const getPercent = useCallback(
        (value: number) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange({ min: minVal, max: maxVal });
    }, [minVal, maxVal, onChange]);

    useEffect(() => {
        setMinVal(originalMin);
        setMaxVal(originalMax);
    }, []);


    return (
        <div className="absolute bg-white border border-1 md:bg-blue-light rounded-md p-2 -mt-2.5 z-10 md:z-0" hidden={hidden}>
            <div className="flex flex-row justify-between my-4 gap-2">
                <Input
                    disabled={true}
                    value={secondsToDisplayable(minVal)}
                    className="w-[1/3] px-1 py-0.5 text-xs text-white bg-blue border-white"
                    onChange={() => { }}
                />
                <span className="text-white text-2xl self-center">-</span>
                <Input
                    disabled={true}
                    value={secondsToDisplayable(maxVal)}
                    className="w-[1/3] px-1 py-0.5 text-xs text-white bg-blue border-white border-2"
                    onChange={() => { }}
                />
            </div>
            <div className="m-auto relative h-[25px] w-[200px] rounded-md">
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={minVal}
                    onChange={(event) => {
                        const value = Math.min(Number(event.target.value), maxVal - 1);
                        setMinVal(value);
                        minValRef.current = value;
                    }}
                    style={{ zIndex: minVal > max - 100 ? "20" : "" }}
                    className="thumb thumb--left"
                />
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={maxVal}
                    onChange={(event) => {
                        const value = Math.max(Number(event.target.value), minVal + 1);
                        setMaxVal(value);
                        maxValRef.current = value;
                    }}
                    className="thumb thumb--right"
                />
                <div className="slider">
                    <div className="slider__track" />
                    <div ref={range} className="slider__range" />
                </div>
            </div>
        </div>
    );
};

export default MultiRangeSlider;
