import { IPartecipant } from "./interfaces";

export enum TimeKind {
    'compensed',
    'real'
}

export const participantToSeconds = (participant: IPartecipant, set: TimeKind) => {
    const hours = participant[`hours_${TimeKind[set]}` as keyof IPartecipant];
    const minutes = participant[`minutes_${TimeKind[set]}` as keyof IPartecipant];
    const seconds = participant[`seconds_${TimeKind[set]}` as keyof IPartecipant];
    return hours * 3600 + minutes * 60 + seconds;
}

export const secondsToDisplayable = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const secs = seconds - hours * 3600 - minutes * 60;
    return `${hours}h ${minutes}' ${secs}''`;
}

export const participantToDisplayable = (participant: IPartecipant, set: TimeKind) => {
    const seconds = participantToSeconds(participant, set);
    return secondsToDisplayable(seconds);
}

export const getParticipantsInTimeRange = (participants: IPartecipant[], min: number, max: number, set: TimeKind) => {
    return participants.filter(participant => {
        const seconds = participantToSeconds(participant, set);
        return (seconds >= min) && (seconds <= max);
    });
}

export const getMaxAndMinTime = (participants: IPartecipant[], set: TimeKind) => {
    const seconds = participants.map(participant => participantToSeconds(participant, set));
    return {
        max: Math.max(...seconds.filter(s => !isNaN(s))),
        min: Math.min(...seconds.filter(s => !isNaN(s)))
    }
}
